<template>
  <b-modal
    id="modal-select-columns"
    size="md"
    centered
    header-bg-variant="light-primary"
    no-close-on-backdrop
    body-class="p-1"
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :title="title"
    hide-footer
    @show="onShowHandle"
  >
    <BRow>
      <BCol
        v-for="(colValue, colKey) in columns"
        :key="colKey"
        cols="12"
        md="6"
      >
        <BFormCheckbox v-model="columns[colKey]">
          {{ $t(`invoice.columns.${colKey}`) }}
        </BFormCheckbox>
      </BCol>
    </BRow>

    <!-- ANCHOR footer button -->
    <div class="d-flex-between mt-1">
      <div class="d-flex-center gap-2">
        <BButton
          variant="outline-primary"
          @click="onSetDefaultHandle"
        >
          {{ $t('invoice.setDefault') }}
        </BButton>
        <BButton
          variant="flat-danger"
          @click="onShowAllHandle"
        >
          {{ $t('invoice.showAll') }}
        </BButton>
      </div>
      <div class="d-flex-center justify-content-end">
        <BButton
          variant="primary"
          @click="onApplyHandle"
        >
          {{ $t('apply') }}
        </BButton>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  BButton,
  BCol,
  BFormCheckbox,
  BModal,
  BRow,
} from 'bootstrap-vue'

import { INVOICE_LIST_COLUMNS, INVOICE_LIST_FIXED_COLUMNS, INVOICE_APP_STORE_MODULE_NAME } from '@/constants/invoice'
import store from '@/store'

export default {
  components: {
    BModal, BButton, BFormCheckbox, BRow, BCol,
  },

  props: {
    title: { type: String, default: 'Thiết lập cột hiển thị' },
  },

  setup(props, { emit, root }) {
    const columnsInStore = computed(() => store.getters[`${INVOICE_APP_STORE_MODULE_NAME}/getColumnsList`])
    function convertColumnsList(type = null) { // type = 'ALL' || 'DEFAULT'
      return INVOICE_LIST_COLUMNS.reduce((a, c) => {
        if (INVOICE_LIST_FIXED_COLUMNS.includes(c.key)) return a
        return ({
          ...a,
          [c.key]: type === 'DEFAULT'
            ? !!c.default
            : type === 'ALL' || columnsInStore.value.includes(c.key),
        })
      }, {})
    }

    const columns = ref()
    function onSetDefaultHandle() {
      columns.value = convertColumnsList('DEFAULT')
    }

    function onShowAllHandle() {
      columns.value = convertColumnsList('ALL')
    }
    function onApplyHandle() {
      const columnsArray = Object.keys(columns.value).filter(key => columns.value[key])
      emit('apply', columnsArray)
      root.$bvModal.hide('modal-select-columns')
    }

    function onShowHandle() {
      columns.value = convertColumnsList()
    }
    return {
      onApplyHandle,
      columns,
      onSetDefaultHandle,
      onShowAllHandle,
      onShowHandle,
    }
  },
}
</script>
