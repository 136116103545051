var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-select-columns",
      "size": "md",
      "centered": "",
      "header-bg-variant": "light-primary",
      "no-close-on-backdrop": "",
      "body-class": "p-1",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.title,
      "hide-footer": ""
    },
    on: {
      "show": _vm.onShowHandle
    }
  }, [_c('BRow', _vm._l(_vm.columns, function (colValue, colKey) {
    return _c('BCol', {
      key: colKey,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('BFormCheckbox', {
      model: {
        value: _vm.columns[colKey],
        callback: function callback($$v) {
          _vm.$set(_vm.columns, colKey, $$v);
        },
        expression: "columns[colKey]"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("invoice.columns.".concat(colKey))) + " ")])], 1);
  }), 1), _c('div', {
    staticClass: "d-flex-between mt-1"
  }, [_c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('BButton', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.onSetDefaultHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.setDefault')) + " ")]), _c('BButton', {
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.onShowAllHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.showAll')) + " ")])], 1), _c('div', {
    staticClass: "d-flex-center justify-content-end"
  }, [_c('BButton', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onApplyHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('apply')) + " ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }